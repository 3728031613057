/* After editing this file, please run:

cd styles; npx tsc colors.js --declaration --allowJs --emitDeclarationOnly; npx prettier --write colors.d.ts

 */

const literal = {
  transparent: '#FFFFFF00',
  white: '#FFFFFF',
  black: '#000000',
  pink: {
    500: '#FFCDED',
  },
  purple: {
    700: '#7E22CE',
  },
  blue: {
    50: '#F1F3FE',
    100: '#D5DBFC',
    200: '#AFBCF9',
    300: '#8B9DF6',
    400: '#677EF2',
    500: '#4460ED',
    600: '#2142E7',
    700: '#182FA5',
    800: '#0E1C63',
    900: '#050920',
  },
  gray: {
    50: '#F9FAFB',
    100: '#f3f4f6',
    200: '#e5e7eb',
    300: '#d1d5db',
    400: '#9ca3af',
    500: '#6b7280',
    600: '#4b5563',
    700: '#374151',
    800: '#1f2937',
    900: '#111827',
  },
  green: {
    50: '#DBF5E6',
    100: '#B8E9CC',
    200: '#97DCB3',
    300: '#77CD9A',
    400: '#58BD81',
    500: '#3BAB68',
    600: '#1F9850',
    700: '#166C39',
    800: '#0D4122',
    900: '#04150B',
  },
  yellow: {
    50: '#FFF9E6',
    100: '#FFEFC0',
    200: '#FFE699',
    300: '#FFDC73',
    400: '#FFD34D',
    500: '#FFC927',
    600: '#FFC001',
    700: '#997301',
    800: '#725600',
    900: '#2B2100',
  },
  red: {
    50: '#FFF2F3',
    100: '#FFCACF',
    200: '#FFA3AD',
    300: '#FF7E8C',
    400: '#FD5C6D',
    500: '#F63C50',
    600: '#EE1E34',
    700: '#AA1525',
    800: '#660D16',
    900: '#210407',
  },
}

const semantic = {
  'default-hotspot': literal.purple[700],
  primary: {
    surface: literal.blue[50],
    'surface-hover': literal.blue[100],
    'action-hover': literal.blue[500],
    action: literal.blue[600],
    border: literal.blue[700],
  },
  success: {
    surface: literal.green[50],
    'surface-hover': literal.green[100],
    'action-hover': literal.green[500],
    action: literal.green[600],
    border: literal.green[700],
  },
  critical: {
    surface: literal.red[50],
    'surface-hover': literal.red[100],
    'action-hover': literal.red[500],
    action: literal.red[600],
    border: literal.red[700],
  },
  warning: {
    surface: literal.yellow[50],
    'surface-hover': literal.yellow[100],
    'action-hover': literal.yellow[500],
    action: literal.yellow[600],
    border: literal.yellow[700],
  },
}

const colors = {
  ...literal,
  ...semantic,
}

module.exports = colors
